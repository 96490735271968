/*
プロジェクトの気になるボタンをクリックしたときにBrazeのイベントを送信する
*/
$(function() {
  try {
    const isNative = window.navigator.userAgent.toLowerCase().match(/makuake-ios$/i)
        || window.navigator.userAgent.toLowerCase().match(/makuake-android$/i);
    if (isNative) {
      return;
    }

    $('[data-geenie-project-good="off"]').on('click', function() {
      const projectId = $('[data-geenie-project-id]').data('geenieProjectId');
      $.ajax({
        dataType: 'json',
        type: 'get',
        url: '/api/projects/' + projectId,
        success: function(project) {
          window.braze.logCustomEvent('favorite_project', {
            timestamp: new Date(),
            project_id: project.id,
            project_code: project.code,
            project_name: project.title,
            image_url: project.image_url,
            is_coming_soon: project.is_coming_soon,
            is_finished: project.is_finished,
            tag: project.tags.map(tag => ({id: tag.tag_id})),
          });
        },
        error: function(_, status, error) {
          console.error('error occurred in braze_favorite_project.js: ', status, error);
        }
      })
    });
  } catch (error) {
    console.error('error occurred in braze_favorite_project.js: ', error);
  }
});
